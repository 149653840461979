<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area">
    <div class="container">
        <div class="row">

            <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
                <div style="text-align: center;"><img src="assets/img/service/education.jpg" alt="Images"
                        height="500px" /></div>
                <h2>Ecoleaide - A complete edu solutions</h2>
                <h5>Industry <span style="color: #01afee;">Educational Institutions </span></h5>

            </div>
            <div class="col-lg-8 col-md-12">
                <div class="services-left">

                    <p>
                        Ecoleaide - one stop solution for complete Campus Management Process (University, Professional
                        Colleges, Arts and Science Colleges, Institutes, School etc.). From complex and distributed
                        Admission processes to on-boarding, Time-table to Syllabus Management, Attendance to Exam
                        Process, Student fee to Staff Payroll, Library, Students Transportation, Library and Hostel to
                        Inventory management and many more that are coming under one roof - ECOLEAIDE.
                    </p>
                    <p>The primary vision is to make your task more easy while Ecoleaide will automate the complexities.
                        It is secure and fast cloud-based Campus Management Software that can be accessed from anywhere,
                        anytime by the Institution Management, HoDs, Class teachers, Teachers, Office Staffs, Parents
                        and students. Loaded with all the latest technology aspects and features you can enjoy the
                        analysis and intelligent reports at your figure tips.</p>
                    <p>
                        We focuses on enhanced data security as a student and school related data is very crucial. It
                        makes the complex school processes like exam management, inventory management, attendance
                        management, etc in a streamlined manner. It is cloud bases ERP thus could access from anywhere,
                        anytime easily. Another reason to prefer this software is the availability of the support team
                        for any issue that occurred for reliable resolutions.
                    </p>
                    <b style="color: #01afee;">Ecoleaide helps</b>
                    <ul>
                        <li>
                            To gain faster insights for enhanced academic performance.</li>
                        <li>
                            To stay organized with the help of efficient modules to manage the infrastructure.
                        </li>
                        <li>
                            Makes the communication efficiently
                        </li>
                    </ul>
                    <p>Ecoleaide equipped with more than 25+ modules and is simple to use which will helps to maintain
                        the workflows and processes hassle-freely</p>
                    <p>Ecoleaide focuses on enhanced data security and enabled end-to-end encryption as the information
                        of a student or parent or teacher or any stake holders and school related data is very crucial
                        and critical in nature. It makes the complex school processes like Admission, Timetable
                        generation, Exam Handling etc. in a streamlined and smart manner. Feel the remarkable service
                        from Ecoleaide Support that might be the another reason to prefer Ecoleaide - Campus Management
                        Software for your institution.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:919633306074">+91 9633306074</a></span>
                                    <span><a href="tel:914712446383">+91 471 2446383</a></span>

                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:sales@techpool.co.in">sales@techpool.co.in</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="services-widget-two">
                        <h2>Brochures</h2>
                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>Product Brochure</h3>
                                    <span><a href="assets/files/Techpool Ecoleaide.pdf"
                                            target="_blank">Download</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="services-widget-two">
                        <ul>
                            <li>
                                <div class="content">
                                    <h3>Visit Product Page</h3>
                                    <span><a href="https://ecoleaide.com/" target="_blank">www.ecoleaide.com</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="widget-category">
                        <h3>Other Products</h3>

                        <ul>
                            <li><a routerLink="/fintech">Fintech Application</a></li>
                            <li><a routerLink="/aero">Aero Management</a></li>
                            <li><a routerLink="/erp">Enterprise Resource Planning</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
