<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Contact Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="contact-area">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Contact</span>
            <h2>Hello.<b>We're always ready to help.</b></h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-planet-earth"></i>
                    <h3>Office Locations</h3>
                    <p style="color: #01afee;">TRIVANRUM OFFICE</p>
                    <p>2nd Floor, M G Corporate Building</p>
                    <p>Ulloor, Medical College P O</p>
                    <p>Trivandrum - 695011</p>
                    <p>Kerala</p>
                    <p style="color: #01afee;">KOCHI OFFICE</p>
                    <p>MDRA-50, 1st Floor</p>
                    <p>Morarji Desai Rd vaazhakala</p>
                    <p>Edappally, Kochi - 682021</p>
                    <p>Kerala</p>
                    <!-- <a target="_blank" href="#" class="contact-card-btn">Direction <i class='bx bx-plus plus-btn'></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="contact-card">
                    <i class="flaticon-email"></i>
                    <h3>Contact</h3>
                    <p>
                        Email:
                        <a href="mailto:info@techpool.co.in">info@techpool.co.in</a>
                    </p>
                    <p>
                        Office:
                        <a href="tel:+91 471 2446383">+91 471 2446383</a>
                    </p>
                    <p></p>
                    <p>
                        Sales Enquiry:
                        <a href="tel:+91 9567761050">+91 9567761050</a>
                    </p>
                    <p>
                        Service Enquiry:
                        <a href="tel:+91 9746124960">+91 9746124960</a>
                    </p>
                    <p>
                        HR Enquiry:
                        <a href="tel:+91 9633204833">+91 9633204833</a>
                    </p>
                    <!-- <a routerLink="/" class="contact-card-btn">Know More <i class='bx bx-plus plus-btn'></i></a> -->
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="contact-card">
                    <i class="flaticon-clock"></i>
                    <h3>Hours of Operation</h3>
                    <p>Monday - Friday: 09:30 - 18:00</p>
                    <p>Saturday: 09:30 - 13:00</p>
                    <p>Sunday Holiday</p>
                    <!-- <a href="mailto:info@ribo.com" class="contact-card-btn">Support <i class='bx bx-plus plus-btn'></i></a> -->
                </div>
            </div>
        </div>
    </div>
    <div class="blog-shape">
        <div class="shape1">
            <img src="assets/img/shape/shape1.png" alt="Images" />
        </div>
        <div class="shape2">
            <img src="assets/img/shape/shape5.png" alt="Images" />
        </div>
        <div class="shape3">
            <img src="assets/img/shape/shape4.png" alt="Images" />
        </div>
        <div class="shape4">
            <img src="assets/img/shape/shape6.png" alt="Images" />
        </div>
    </div>
</div>

<div class="contact-section work-area-two pt-100 pb-50">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="contact-map">
                    <iframe src="https://www.google.com/maps/d/embed?mid=1r3y4EeXqnCvZLyABijLFvgKGsYFcKRrw&hl=en"></iframe>
                        <!-- <iframe frameborder=0 style='width:100%;height:500px' src='//www.zeemaps.com/pub?group=4270405'> </iframe> -->
                        <!-- <iframe width="100%" height="600" src="https://maps.mapifator.com/places/3lX3k84pmphsedkTrxb7?panelMinimized=true" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" align="center"></iframe> -->
                        <!-- <iframe width="550" height="400" frameborder="0" src="https://widgets.scribblemaps.com/sm/?d&z&l&gc&af&mc&lat=8.5318971&lng=76.9286609&vz=7&type=road&ti&s&width=550&height=400&id=oNa4htFyfZ" style="border:0; max-width: 100%;" allowfullscreen allow="geolocation" loading="lazy"></iframe> -->
                    </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="section-title">
                            <h2><b>Connect With</b> Us</h2>
                        </div>

                        <form [formGroup]="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class="bx bx-user"></i>
                                        <input type="text" name="name" id="name" class="form-control" required
                                            placeholder="Your Name*" formControlName="contactFormName" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class="bx bx-user"></i>
                                        <input type="email" name="email" id="email" class="form-control" required
                                            placeholder="E-mail*" formControlName="contactFormEmail" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class="bx bx-phone"></i>
                                        <input type="text" name="phone_number" id="phone_number" required
                                            class="form-control" placeholder="Your Phone*"
                                            formControlName="contactFormMobile" />
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <i class="bx bx-file"></i>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control"
                                            required placeholder="Your Subject*" formControlName="contactFormSubject" />
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <i class="bx bx-envelope"></i>
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="8"
                                            required placeholder="Your Message*"
                                            formControlName="contactFormMessage"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button *ngIf="disabledSubmitButton" style="cursor: pointer"
                                        class="default-btn border-radius" (click)="onSubmit()">
                                        Send Message <i class="bx bx-plus"></i>
                                    </button>
                                    <button *ngIf="!disabledSubmitButton" class="default-btn border-radius">
                                        Message Sending..
                                        <i class="bx bx-mail-send"></i>
                                    </button>
                                    <label *ngIf="showMsg" style="
                                            color: #01afee;
                                            font-weight: bold;
                                            text-transform: uppercase;
                                            float: right;
                                        ">Message Send Successfully</label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--
<div class="contact-map">
    <div class="container-fluid m-0 p-0">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12083.001749463827!2d-74.1283896536106!3d40.78950036208337!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c255dc66381f65%3A0x9ba18d7d96da3914!2sRoma!5e0!3m2!1sen!2sbd!4v1598511303135!5m2!1sen!2sbd"></iframe>
    </div>
</div> -->

<app-footer-style-one></app-footer-style-one>
