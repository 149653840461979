import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { SoftwareServicesComponent } from './components/pages/software-services/software-services.component';
import { ItServicesComponent } from './components/pages/it-services/it-services.component';
import { SystemServicesComponent } from './components/pages/system-services/system-services.component';
import { GisServicesComponent } from './components/pages/gis-services/gis-services.component';
import { UtilityServicesComponent } from './components/pages/utility-services/utility-services.component';
import { CloudServicesComponent } from './components/pages/cloud-services/cloud-services.component';

import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CaseArmsComponent } from './components/pages/case-arms/case-arms.component';
import { FintechComponent } from './components/pages/fintech/fintech.component';
import { AeroComponent } from './components/pages/aero/aero.component';
import { EducationComponent } from './components/pages/education/education.component';
import { ErpComponent } from './components/pages/erp/erp.component';

const routes: Routes = [
    { path: '', component: SeoAgencyComponent },
    // {path: 'ai-startup', component: AiStartupComponent},
    // {path: 'machine-learning', component: MachineLearningComponent},
    { path: 'about', component: AboutComponent },
    { path: 'team', component: TeamComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'faq', component: FaqComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'case-study', component: CaseStudyComponent },
    { path: 'case-arms', component: CaseArmsComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'terms-conditions', component: TermsConditionsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'services-details', component: ServicesDetailsComponent },
    { path: 'software-services', component: SoftwareServicesComponent },
    { path: 'it-services', component: ItServicesComponent },
    { path: 'system-services', component: SystemServicesComponent },
    { path: 'gis-services', component: GisServicesComponent },
    { path: 'utility-services', component: UtilityServicesComponent },
    { path: 'cloud-services', component: CloudServicesComponent },
    { path: 'fintech', component: FintechComponent },
    { path: 'aero', component: AeroComponent },
    { path: 'education', component: EducationComponent },
    { path: 'erp', component: ErpComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },
    { path: 'contact', component: ContactComponent },


    { path: '**', component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        anchorScrolling: 'enabled', relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
