<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-widget-area pt-100">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-card" routerLink="/software-services">
                    <img src="assets/img/service/data.png" alt="Images">
                    <h3>SOFTWARE SERVICES</h3>
                    <p><b>WE</b> offers state of art software services including web application development, web platform development, mobile application development, hospital information systems and lot more. </p>
                    <div class="services-card-shape">
                        <img src="assets/img/service/service-shape.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card" routerLink="/it-services">
                    <img src="assets/img/service/support-services.png" alt="Images">
                    <h3>IT CONSULTANCY SERVICES</h3>
                    <p><b>WE</b> offers services in IT consultancy, project consultancy, DPR preparation, project monitoring and management, network security, system design and hardware, data centre, power auditing and electrical consultancy services.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card" routerLink="/system-services">
                    <img src="assets/img/service/system.png" alt="Images">
                     <h3>SYSTEM INTEGRATION SERVICES</h3>
                     <p> <b>WE</b> offers flawless, scalable and user friendly customised integrated system with a reliable back end support.</p>
                     <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                 </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card" routerLink="/gis-services">
                    <img src="assets/img/service/gis.png" alt="Images">
                     <h3>GIS SERVICES</h3>
                     <p><b>WE</b> offers high quality professional education, Foster communications among IT/GIS
                         management professionals in provincial/state and local government, and Encourage a multi-disciplinary approach to the design and use of urban and regional information systems</p>
                     <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                 </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card" routerLink="/utility-services">
                    <img src="assets/img/service/utility-rate.png" alt="Images">
                     <h3>WATER & UTILITY CONSULTANCY SERVICES</h3>
                     <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit aut fugitsed quia consequuntur alquam quaerat voluptatem </p>
                     <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                 </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card" routerLink="/cloud-services">
                    <img src="assets/img/service/cloud.png" alt="Images">
                    <h3>CLOUD SERVICES</h3>
                    <p><b>WE</b> offers services on cloud include,Cloud hosting Service, Hosted data back service, Cloud Hosted application services and lot more. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
