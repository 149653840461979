import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-software-services',
  templateUrl: './software-services.component.html',
  styleUrls: ['./software-services.component.scss']
})
export class SoftwareServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
