import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/common.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    contactForm: FormGroup;
    disabledSubmitButton: boolean = true;
    showMsg: boolean = false;
    constructor(
        private fb: FormBuilder,
        private connectionService: CommonService
    ) {}

    ngOnInit(): void {
        const mobile = /(6|7|8|9)\d{9}/;

        this.contactForm = this.fb.group({
            contactFormName: ['', Validators.required],
            contactFormMobile: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(mobile),
                ]),
            ],
            contactFormEmail: [
                '',
                Validators.compose([Validators.required, Validators.email]),
            ],
            contactFormSubject: ['', Validators.required],
            contactFormMessage: ['', Validators.required],
        });
    }
    get c() {
        return this.contactForm.controls;
    }

    onSubmit() {
        if (this.contactForm.valid) {
            this.disabledSubmitButton = false;
            this.connectionService
                .sendMessage(this.contactForm.value)
                .subscribe(
                    (res) => {
                        if (res === 'sent') {
                            this.showMsg = true;
                            this.contactForm.reset();
                            setTimeout(() => {
                                this.showMsg = false;
                            }, 5000);
                            this.disabledSubmitButton = true;
                        }
                    },
                    (error) => {
                        console.log('Error', error);
                    }
                );
        }
    }
}
