<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Blog</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="blog-area pt-100 pb-50">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Blogs</span>
            <h2 class="h2-color">We’re Getting Ready <b>Stay Tuned!</b></h2>
        </div>
<!--
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog7.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 14 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practice for Web Traffic</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog8.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 15 August 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Best Guidelines for a Digital Marketing</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog9.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Web</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">Web Architecture Best Practices & Guides</a></h3>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
