<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="services-left">
                <div class="services-content">
                    <img src="assets/img/service/services.png" alt="Images" />
                    <h2 style="text-align: center">
                        GEOGRAPHIC INFORMATION SYSTEM SERVICES
                    </h2>

                    <p>
                        Today a well-developed GIS systems are very essential to
                        protect the environment. It has turned into a
                        coordinated, well developed and successful tool in
                        disaster management and mitigation. GIS can help with
                        risk management and analysis by showing which regions
                        are likely to be prone to natural or man-made disasters.
                    </p>
                    <p>
                        Techpool can assist you with GIS data creation to
                        converting it into a fully-fledged web GIS solution. We
                        have a core experienced GIS team to support you with any
                        kind of GIS projects with location data analysis and
                        customized web GIS solutions or if you want assessment
                        of drainage, reservoir, road, building, Flood mapping,
                        modern methodology on your project we can help you with
                        it.
                    </p>
                    <p>
                        The true power of GIS comes when it get integrated with
                        other different technologies and we are well equipped to
                        do that to give you a desired output as per your
                        requirement. Get in touch with us and we will help for a
                        cost effective solutions on your requirements
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
