<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area pt-100">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="services-left">
                <div class="services-content">
                    <img src="assets/img/service/services.png" alt="Images" />
                    <h2 style="text-align: center">IT CONSULTANCY SERVICES</h2>
                    <p>
                        TechPool Consultancy Services offers Software
                        development services and IT Business Consulting Services
                        in those domains where convergence of innovative ideas
                        and technology takes place. With our rich heritage
                        expertise in the fields of Information Technology, we
                        provide guidance for organisations for their IT and
                        Business roadmap as well.
                    </p>
                    <p>Our IT Consultancy Division focus on:</p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                IT Consultancy Support for Smart City Projects
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                IT Consultancy Services for E Governance
                                projects
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                IT Consultancy Services for Fintech / BFSI
                                verticals
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>IT – Healthcare Consultancy Services</h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>ERP Consultancy Services</h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                IT - Data centre, NOC, SOC, Networking and
                                Infrastructure
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                Providing Manpower on Onsite / Shared Resources
                                models
                            </h3>
                        </div>
                    </div>
                </div>

                <div class="content-widget-area">
                    <div class="content-widget-text">
                        <p>IT Consultancy Modules</p>

                        <ul>
                            <li>
                                <i class="bx bxs-star"></i>IT Project
                                Feasibility Study
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Department level
                                workshops
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>IT requirement
                                gathering
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Detailed Project
                                Report (DPR) Preparation
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Tender Document
                                preparation & bid evaluation support
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Project Monitoring
                                Support during Implementation period
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Certification of
                                work progress and progressive Bills/ Invoices of
                                the implementation agency
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Support in overall
                                Project Management
                            </li>
                            <li>
                                <i class="bx bxs-star"></i>Project closures
                                activities
                            </li>
                        </ul>
                    </div>
                </div>

                <p>
                    Techpool resources have strong experience in IT Consultancy
                    starting from Client requirement mapping, preparing RFP,
                    Vendor and SI selection, SRS preparation and PMC required by
                    a client from any domain. A set of Industrial experts
                    handling this business who has more than 25 years of
                    experiences in multiple domains. Techpool handled, many IT
                    consultancy services in Private and Government segments.
                </p>
            </div>
            <p>
                Techpool’s IT Staffing group provides clients with the tools and
                talent they need to design, develop, and manage IT solutions
                that support their strategic business initiatives with expertise
                that spans throughout the IT continuum. Our Technology
                Consultants are skilled in JAVA .Net, MongoDB, ExpressJS,
                AngularJS and Node.js in IT development, delivery, and
                maintenance throughout the product life cycle. Their expertise
                encompasses all the critical IT functions, with an emphasis on:
            </p>

            <div class="content-widget-area">
                <div class="content-widget-text">
                    <ul>
                        <li>
                            <i class="bx bxs-star"></i> Project Management /
                            Project Consultancy / Project Monitoring
                        </li>
                        <li><i class="bx bxs-star"></i> QA and Testing</li>
                        <li><i class="bx bxs-star"></i> Web Services</li>
                        <li>
                            <i class="bx bxs-star"></i> Flexible Staffing
                            Options
                        </li>
                    </ul>
                </div>
            </div>
            <p>
                Techpool offers the full spectrum of delivery options to suit
                each client’s schedule and workforce needs. We provide staff
                augmentation by the person, project, or team, or take on a
                blended engagement. Consultants can be retained for both mid-
                and long-term assignments. For complex projects requiring the
                most skilled and experienced IT professionals, clients can work
                directly with our Staff Consultants. These high-level
                Consultants continually augment their skill sets with the help
                of our IT Alliance partners, who ensure they stay current with
                the latest best-in-class tools and techniques.
            </p>
        </div>
    </div>

    <app-footer-style-one></app-footer-style-one>
</div>
