<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area">
    <div class="container">
        <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
            <div style="text-align: center;"><img src="assets/img/service/fintech1.jpg" alt="Images" height="500px" />
            </div>
        </div>
        <div class="tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="marzen" checked>
            <label for="tab1">CKYC Solution</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="rauchbier">
            <label for="tab2">Forex - FFMC Management Solution</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3" aria-controls="dunkles">
            <label for="tab3">Forex AD-II Solution</label>

            <div class="tab-panels">
                <section id="marzen" class="tab-panel">
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
                            <h2> CKYC Solution </h2>
                            <h5>Industry <span style="color: #01afee;">Leading NBFC, Forex, Nidhi, Urban Co-operative
                                    Banks..</span></h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="services-left">

                                <p>
                                    Government of India has authorised the Central Registry of Securitisation Asset
                                    Reconstruction and Security Interest of India (CERSAI), to act as, and to perform
                                    the functions of the CKYCR vide Gazette Notification No. S.O. 3183(E) dated November
                                    26, 2015.
                                    RBI/DBR/2015-16/18 Master Direction DBR.AML.BC.No.81/14.01.001/2015-16 - Section
                                    56(a)
                                </p>
                                <p>
                                    Techpool cKYC simplifies the KYC process of Financial Institutions (FI) or any other
                                    Institutions like Hospitals, Internet Service Providers, Network Operators etc..
                                    Once cKYC is approved by the CERSAI the customer is identified with KYC
                                    Identification Number (KIN) and after that any financial transactions like
                                    insurance, mutual funds, loan processing etc. are not required the KYC process
                                    instead FI can process the KYC through KIN.
                                </p>

                                <ul>
                                    <li>
                                        KIN or KYC Identifier is a unique 14 digit identifier assigned to the customer
                                        based on the KYC information uploaded by a financial institution on CKYCR
                                    </li>
                                    <li>
                                        cKYC is valid pan India and can be used while opening an account with any
                                        financial institutions regulated by RBI, SEBI, IRDAI, and PFRDA
                                    </li>
                                    <li>By submitting the document at one place it will be recorded in central
                                        repository</li>
                                </ul>
                                <b style="color: #01afee;">Key Features / Benefits</b>
                                <ul>
                                    <li>
                                        The entire business process is complied with regulatory requirements
                                    </li>
                                    <li>
                                        Business Intelligence and Analytics are incorporated
                                    </li>
                                    <li>
                                        An AI solution with is converting traditional paper based account opening to
                                        Digital Account
                                    </li>
                                    <li>Helps BFSI organizations to comply with cKYC and AML guidelines </li>
                                    <li>Enhanced operational efficiency and increase the productivity</li>
                                    <li>Substantial cost reduction
                                    </li>
                                    <li>Reduce manual processing and eliminate the errors</li>
                                    <li>Better turnaround time</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="services-widget-right">
                                <div class="contact-widget">
                                    <h2>Contact Info</h2>

                                    <ul>
                                        <li>
                                            <i class="flaticon-telephone"></i>
                                            <div class="content">
                                                <h3>Phone: </h3>
                                                <span><a href="tel:919633204833">+91 9633204833</a></span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class="flaticon-email-1"></i>
                                            <div class="content">
                                                <h3>Email:</h3>
                                                <span><a href="mailto:info@techpool.co.in">info@techpool.co.in</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <!-- <div class="services-widget-two">
                                    <h2>Brochures</h2>
                                    <ul>
                                        <li>
                                            <i class="flaticon-pdf-file"></i>
                                            <div class="content">
                                                <h3>01:</h3>
                                                <span><a>PDF Download</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="widget-category">
                                    <h3>Other Products</h3>

                                    <ul>
                                        <li><a routerLink="/aero">Aero Management</a></li>
                                        <li><a routerLink="/education">Education</a></li>
                                        <li><a routerLink="/erp">Enterprise Resource Planning</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>
                <section id="rauchbier" class="tab-panel">
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
                            <h2>Forex - FFMC Management Solution </h2>
                            <h5>Industry <span style="color: #01afee;">NBFC and Forex</span></h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="services-left">

                                <p>
                                    Foreign Exchange trading, also known as Forex or FX trading has gained enormous
                                    popularity in recent years due to the growth of online brokers and the technological
                                    development of online trading platform. We have a wonderful solution to meet the
                                    challenges in the Forex trading and management. It includes almost every module and
                                    custom reports that will make the trade houses ease at their reporting and
                                    administration procedures.
                                </p>
                                <p>
                                    Major NBFC and Forex Institutions are using our Forex Management solution to ease
                                    their routine currency monitoring, real-time exchange rates, predicting trends in
                                    exchange rates using artificial Intelligence and reports for RBI in daily weekly
                                    monthly basis.
                                </p>
                                <b style="color: #01afee;">Key Features / Benefits</b>
                                <ul>
                                    <li>
                                        Branch management </li>
                                    <li>
                                        Franchise Management </li>
                                    <li>
                                        Treasury Management </li>
                                    <li>Assigning privileges to users </li>
                                    <li>Agent creation and approval</li>
                                    <li>FFMC creation and approval
                                    </li>
                                    <li>RMC creation and approval</li>
                                    <li>Corporate client creation and approval</li>
                                    <li>Currency profile with buy rate, sell rate, lower currency rate and approval</li>
                                    <li>Live currency rates.(3rd party integration)</li>
                                    <li>Stock entry and removal</li>
                                    <li>Stock transfer, receive and approval</li>
                                    <li>Employee incentive / Agent commission</li>
                                    <li>Buying currency with option of lower currency purchase</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="services-widget-right">
                                <div class="contact-widget">
                                    <h2>Contact Info</h2>

                                    <ul>
                                        <li>
                                            <i class="flaticon-telephone"></i>
                                            <div class="content">
                                                <h3>Phone: </h3>
                                                <span><a href="tel:919633204833">+91 9633204833</a></span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class="flaticon-email-1"></i>
                                            <div class="content">
                                                <h3>Email:</h3>
                                                <span><a href="mailto:info@techpool.co.in">info@techpool.co.in</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="services-widget-two">
                                    <h2>Brochures</h2>
                                    <ul>
                                        <li>
                                            <i class="flaticon-pdf-file"></i>
                                            <div class="content">
                                                <h3>Product Brochure</h3>
                                                <span><a href="assets/files/Techpool Forex.pdf" target="_blank">Download</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="widget-category">
                                    <h3>Other Products</h3>

                                    <ul>
                                        <li><a routerLink="/aero">Aero Management</a></li>
                                        <li><a routerLink="/education">Education</a></li>
                                        <li><a routerLink="/erp">Enterprise Resource Planning</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
                <section id="dunkles" class="tab-panel">
                    <div class="row">
                        <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
                            <h2>Forex AD-II Solution </h2>
                            <h5>Industry <span style="color: #01afee;">Leading NBFC, Urban Co-operative Banks..</span>
                            </h5>
                        </div>
                        <div class="col-lg-8 col-md-12">
                            <div class="services-left">

                                <p>
                                    The RBI permits certain entities to undertake more transactions, in addition to what
                                    FFMCs are currently permitted, by authorizing them to undertake release / remittance
                                    of foreign exchange for certain non-trade related current account transactions for
                                    the purpose of widening the scope of activities which the Authorized Persons are
                                    currently eligible to undertake and also to increase the number of entities that are
                                    eligible to sell foreign exchange to public for their day-to-day current account
                                    transactions. Such entities are called as Authorized Dealers – Category II (ADs –
                                    Category II).
                                </p>
                                <b style="color: #01afee;">ADs-Category II will be permitted to release / remit foreign
                                    exchange for the following transactions</b>

                                <ul>
                                    <li>Private visits</li>
                                    <li>Remittance by tour operators / travel agents to overseas agents / principals /
                                        hotels</li>
                                    <li>Business travel</li>
                                    <li>
                                        Fee for participation in global conferences and specialized training
                                    </li>
                                    <li>
                                        Remittance for participation in international events / competitions (towards
                                        training, sponsorship and prize money).
                                    </li>
                                    <li>
                                        Film shooting
                                    </li>
                                    <li>
                                        Medical treatment abroad
                                    </li>
                                    <li>
                                        Disbursement of crew wages
                                    </li>
                                    <li>
                                        Overseas Education
                                    </li>
                                    <li>
                                        Remittance under educational tie up arrangements with universities abroad
                                    </li>
                                    <li>
                                        Remittance towards fees for examinations held in India and abroad and additional
                                        score sheets for GRE, TOEFL etc.
                                    </li>
                                    <li>
                                        Employment and processing, assessment fees for overseas job applications
                                    </li>
                                    <li>
                                        Employment and processing, assessment fees for overseas job applications
                                    </li>
                                    <li>
                                        Skills / credential assessment fees for intending migrants
                                    </li>
                                    <li>Visa fees</li>
                                    <li>
                                        Processing fees for registration of documents as required by the Portuguese /
                                        other Governments
                                    </li>
                                    <li>
                                        Registration / Subscription / Membership fees to International Organizations.
                                    </li>
                                </ul>
                                <p>ADs – Category II may also be permitted to undertake additional activities as decided
                                    by the
                                    Reserve Bank, on case by case basis</p>
                                <b style="color: #01afee;">Key Features / Benefits</b>
                                <ul>
                                    <li>
                                        Branch management </li>
                                    <li>
                                        Franchise Management </li>
                                    <li>
                                        Dealing Room for each transaction category</li>
                                    <li>Treasury Management</li>
                                    <li>Assigning privileges to users </li>
                                    <li>Agent creation and approval</li>
                                    <li>FFMC creation and approval
                                    </li>
                                    <li>RMC creation and approval</li>
                                    <li>Corporate client creation and approval</li>
                                    <li>Currency profile with buy rate, sell rate, lower currency rate and approval</li>
                                    <li>Live currency rates.(3rd party integration)</li>
                                    <li>Stock entry and removal</li>
                                    <li>Stock transfer, receive and approval</li>
                                    <li>Employee incentive / Agent commission</li>
                                    <li>Buying currency with option of lower currency purchase</li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="services-widget-right">
                                <div class="contact-widget">
                                    <h2>Contact Info</h2>

                                    <ul>
                                        <li>
                                            <i class="flaticon-telephone"></i>
                                            <div class="content">
                                                <h3>Phone: </h3>
                                                <span><a href="tel:919633204833">+91 9633204833</a></span>
                                            </div>
                                        </li>
                                        <li>
                                            <i class="flaticon-email-1"></i>
                                            <div class="content">
                                                <h3>Email:</h3>
                                                <span><a href="mailto:info@techpool.co.in">info@techpool.co.in</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <!-- <div class="services-widget-two">
                                    <h2>Brochures</h2>
                                    <ul>
                                        <li>
                                            <i class="flaticon-pdf-file"></i>
                                            <div class="content">
                                                <h3>01:</h3>
                                                <span><a>PDF Download</a></span>
                                            </div>
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="widget-category">
                                    <h3>Other Products</h3>

                                    <ul>
                                        <li><a routerLink="/aero">Aero Management</a></li>
                                        <li><a routerLink="/education">Education</a></li>
                                        <li><a routerLink="/erp">Enterprise Resource Planning</a></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>


        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
