<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>About Us</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="about-widget-area pt-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">About Us</span>
                        <h2><b>Grow Your Business With</b> Techpool</h2>
                    </div>
                    <p><b>Techpool</b> has completed its 10th successful year- with year on year positive net worth growth and striving to build a strong customer ecosystem which
                        finds genuine value in its mutual engagement. As a strict policy we are very selective as far as client selection is concerned and always tried our
                        best to bring in great value to our customer engagement process. We selectively focus on delivering services like Software development, Information
                        Technology (IT) Consultancy, Project Monitoring, Strategic Management & Business Consultancy services, and system Integration projects.<br>
                    <b>Techpool</b> has been managed by a group of directors, who have wide experience in IT industry and proven professional track records with globally
                        leading MNCs. TechPool Consultancy Services offers Software development services and IT Business Consulting Services in those domains where convergence of
                        innovative ideas and technology takes place. With our rich heritage expertise in the fields of Information Technology, we provide guidance for organisations
                        for their IT and Business roadmap as well. We committed to excellence in delivering superior quality solutions and extended service supports.</p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bxs-star'></i>
                                <p>Provide a professional, versatile and friendly service</p>
                            </li>
                            <li>
                                <i class='bx bxs-star'></i>
                                <p>Ensure reliable  and innovative services</p>
                            </li>
                            <li>
                                <i class='bx bxs-star'></i>
                                <p>Give quality service on industrial standards that add value for money</p>
                            </li>
                            <li>
                                <i class='bx bxs-star'></i>
                                <p>Keeping long lasting and fruitful relationships with our clients</p>
                            </li>
                            <li>
                                <i class='bx bxs-star'></i>
                                <p>Provide harmony at work and recognise & reward the performances to employees</p>
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Play Video </span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>


<div class="counter-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-confetti"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>GLORIOUS YEARS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-project"></i>
                    <div class="content">
                        <h3>25 +</h3>
                        <p>PROJECTS DONE</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-customers"></i>
                    <div class="content">
                        <h3>150 +</h3>
                        <p>ACTIVE CLIENTS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-financial-advisor"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>TEAM ADVISORS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="service-list-area pt-50">
    <div class="container">
        <!-- <div class="section-title text-center">
            <span class="sp-before sp-after">Services</span>
            <h2>Our Journey’s Path</h2>
        </div> -->
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Our Mission</h3>
                    <p><b>Our Mission</b> is to provide quintessential IT consultancy, cloud, software and hardware solutions to our clients which perfectly cater the needs and dreams of our customers through a team of IT experts. </p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="service-list-content">
                    <h3>Our Vision</h3>
                    <p><b>Our Vision</b> is to be a credible and ethical IT consultancy , Software and System Integration brand to Government, Enterprise, BFSI, and Corporate organisations and to help them enhance their
                        productivity and efficiency through our unparalleled service expertise in integration of cutting edge technologies.</p>

                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Our History</h3>
                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                        <li><i class='bx bx-check'></i> Better Best Wow!</li>
                        <li><i class='bx bx-check'></i> Brilliant Minds</li>
                        <li><i class='bx bx-check'></i> Activate Listening</li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
    <div class="service-list-area work-area-two pb-50">
        <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="service-list-content">
                    <h3>Why Choose Techpool</h3>
                    <p><b>Pragmatic, feasible and cost effective service to our clients</b></p>
                    <p><b>WE</b> possess a client focused working attitude and tradition. We assert
                        quality products which are practical feasible and client friendly, we grow through
                        our clients so it is imperative for us to keep our clients happy and satisfied.
                        Our projects are tailored in compliance with client’s requirement, infrastructure and situation.
                        We always ensure the projects are completed on time, to budget and to your exact expectations.</p>
                        <p><b>Innovative digital solutions</b></p>
                        <p><b>WE</b> have a futuristic working attitude. We accept every project, no matter big or small, to create
                            our own signature of excellence. Every project is a challenge for us, we always look to make a positive,
                            measureable difference which in turn add value to your business. Our team culture is a paragon to innovation.</p>
                  <p><b>We always get the job done right</b></p>
                  <p><b>WE</b> have a pool of young talents who are the backbone of our company. We thrive through our expertise in what we do.
                    We always assign right talents and resources to every project to make a seamless solution for your business. Our extremely talented
                    professional service department is bound to give value to your money.</p>
                    <p><b>Experience</b><p>
                        <p> <b>WE</b> have a really stringent interview process that guarantees we only recruit the best among the best, When it comes to resources,
                            experience really matters for us,. Our unabating personal development programs chisel out the best in our employees. We reassure
                            that you, the client, will be dealing with best professionals, who are best at what they do</p>
                    </div>
            </div>
        </div>
    </div>
</div>

<div class="history-area pt-50">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our History</span>
            <h2><b>The Facts About</b> Techpool</h2>
        </div>

        <div class="history-list">
            <ul>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2011</h3>
                        <span>Incorporated in <br>
                        12th December</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2012</h3>
                        <span>Started IT Consultancy <br>Services</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2014</h3>
                        <span>Started System Integration <br>Services and DC Business</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2015</h3>
                        <span>Started Cloud Services <br> Division</span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2017</h3>
                        <span>Reached <br>1 Million $ Milestone
                        </span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2018</h3>
                        <span>Started Software <br>Division
                        </span>
                    </div>
                </li>
                <li>
                    <i class='bx bx-check'></i>
                    <div class="content">
                        <h3>2019</h3>
                        <span>Started GIS <br>Division
                        </span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="history-content">
                    <h2>Great Success of the Year</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip comodo consequa dolor consectetur</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam quis nostrud exercitation </p>
                    <!-- <a routerLink="/about" class="default-btn">Learn more <i class='bx bx-plus'></i></a> -->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="history-img">
                    <img src="assets/img/history-img.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="brand-area">
    <div class="container-fluid">
        <div class="brand-max">
            <div class="brand-title">
                <h3>Our partners</h3>
            </div>
            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                  <img src="assets/img/partners/fortinet_logo.png" alt="Images">
                </div>
                <div class="brand-item"><img src="assets/img/partners/numeric_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                   <img src="assets/img/partners/vertiv_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/hitachi_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/exide_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/amaron_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/molex_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/ospyn_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/havells_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/acer_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/hp_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/cisco_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/f5_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/partners/juniper_logo.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="team-area-two">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Team</span>
            <h2 class="h2-color">Meet Our Experts to Grow Your Business</h2>
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/ragi.jpeg" alt="Images">
                <div class="content">
                    <h3>Ragi K Mathai</h3>
                    <span>Managing Director<br>
                    <a href="mailto:ragi@techpool.co.in">ragi@techpool.co.in</a></span>
                </div>
            </div>

            <div class="team-card active team-rotated-2">
                <img src="assets/img/team/ramesh.png" alt="Images">
                <div class="content">
                    <h3>Ramesh Madhavan</h3>
                    <span>CEO <br><a href="mailto:ramesh@techpool.co.in"> ramesh@techpool.co.in</a></span>

                </div>
            </div>
            <div class="team-card active">
                <img src="assets/img/team/bijo1.png" alt="Images">
                <div class="content">
                    <h3>Bijo Kuruvila</h3>
                    <span>Director Board Member <br><a href="mailto:bijo@techpool.co.in"> bijo@techpool.co.in</a></span>
                </div>
            </div>

            <div class="team-card active team-rotated-2">
                <img src="assets/img/team/kumar.png" alt="Images">
                <div class="content">
                    <h3>K Prasanth Kumar</h3>
                    <span>Director Board Member <br><a href="mailto:kumar@techpool.co.in"> kumar@techpool.co.in</a></span>
                </div>
            </div>
            <!-- <div class="team-card active">
                <img src="assets/img/team/ragi.jpeg" alt="Images">
                <div class="content">
                    <h3>Aiby Mohandas</h3>
                    <span>Director Board Member <br><a href="mailto:aiby@techpool.co.in"> aiby@techpool.co.in</a></span>
                </div>
            </div> -->
        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="testimonial-area pb-50 pt-50">
    <div class="container-fluid">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Testimonials</span>
            <h2 class="h2-color">Words From Customers</h2>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <!-- <h2>Words From Customers</h2> -->
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>David McLean</h3>
                                <span>CEO & Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Jermin Jekson</h3>
                                <span>Marketing Manager</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>I chose Zinka because of their value Andincredible superior customer Service they really awesome treated me like family</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Julfiker Jeain</h3>
                                <span>CEO At Ostino</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="brand-logo-area  pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-one></app-footer-style-one>
