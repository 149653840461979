<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area pt-100">
    <div class="container">
            <div class="col-lg-12 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/services.png" alt="Images">
                        <h2 style="text-align: center;">SOFTWARE SERVICES</h2>
                        <p>A great software product satisfies and fulfils a deep need for its users or address customer business pain points. It changes the way people drive business and often times transform the way industries handle operations. The market always appreciates quality products and we give paramount importance to quality and user delight. We realise that the top software products make people more creative, productive and better at their craft. Great software products are addictive. They not only solve the problems for the users, but also create joyful experience and interactions for them. </p>
                 <p>Techpool started, it’s software Services division in 2019 with a strict policy on selection of technology stacks and focused client approach model. With our rich heritage expertise in the fields of Information Technology, we provide guidance for organisations for their Software roadmap as well. We committed to excellence in delivering superior quality solutions and extended service supports.</p>
                <p>Techpool offers, custom developed software applications as per client needs in identified business verticals.  We have proven and tested software solutions for below verticals:</p>
                </div>

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Finance Vertical</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Airport Vertical</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Government / Enterprise Vertical</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Enterprise Cloud Enablement Services</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Business Process Management and Automation</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Analytics and Data Visualisation</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>Mobile Applications</h3>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="services-content-card">
                                <h3>GIS Solutions</h3>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For Content Marketer</h2>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> The Field of Data Science</li>
                                        <li><i class='bx bx-check'></i> SEO is Uniquely Built Around  </li>
                                        <li><i class='bx bx-check'></i> Google’s search algorithm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore tus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis onsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
                </div>
            </div>
    </div>
</div>

<div class="courses-area">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Products</span>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p> -->
        </div>

        <!-- <div class="row pt-25">
            <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/arms.png" alt="Images">
                    </div>
                    <h3>Airport Revenue Monitoring System</h3>
                    <p><b>Techpool Airport Revenue Monitoring System</b> will help Airports to identify, define and monitor all the conventional revenue streams in an airport.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/forex.png" alt="Images">
                    </div>
                    <h3>Forex Management Solution</h3>
                    <p><b>Techpool Forex Management Solution</b> covers the all essential features required to optimize the risk and maximize the profitability for NBFC's.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/budget.png" alt="Images">
                    </div>
                    <h3>Budgeting Procurement & Inventory Management System</h3>
                    <p><b>Techpool Budgeting Procurement & Inventory Management System</b> provides a centralized budgeting system with clear fund allocation / procurement / inventory management system with centralized accounting.</p>
                </div>
            </div>

             <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/data.png" alt="Images">
                    </div>
                    <h3>Plug & Play DC for Immediate Deployement</h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                </div>
            </div>
        </div> -->
        <div class="row pt-25">
            <div class="col-lg-6">
                <div class="courses-card" routerLink="/fintech">
                    <div class="images">
                        <img src="assets/img/courses/forex.png" alt="Images">
                    </div>
                    <h3>Fintech Application</h3>
                    <p>Financial application like KYC-cKYC, Forex etc which are emerging into the industry by complying the regulations and providing effective solutions in the operational activities</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/education">
                    <div class="images">
                        <img src="assets/img/courses/education.png" alt="Images">
                    </div>
                    <h3>Education</h3>
                    <p>An an enterprise class EDU-ERP system specially designed for 21st century educational Institutions based on their priorities. It takes care of the requirements of all the stakeholders- management, teachers, operational staffs, students and parents</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/aero">
                    <div class="images">
                        <img src="assets/img/courses/arms.png" alt="Images">
                    </div>
                    <h3>Aero Management</h3>
                    <p>Techpool Airport Revenue Monitoring System will help Airports to identify, define and monitor all the conventional revenue streams in an airport</p>
                </div>
            </div>

             <div class="col-lg-6">
                <div class="courses-card" routerLink="/erp">
                    <div class="images">
                        <img src="assets/img/courses/budget.png" alt="Images">
                    </div>
                    <h3>Enterprise Resource Planning</h3>
                    <p>Techpool Budgeting Procurement & Inventory Management System provides a centralized budgeting system with clear fund allocation / procurement / inventory management system with centralized accounting</p>
                </div>
            </div>
        </div>

    </div>
</div>

<app-footer-style-one></app-footer-style-one>
