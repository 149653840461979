<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area">
    <div class="container">
        <div class="row">

            <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
             <div style="text-align: center;"><img src="assets/img/service/aero.jpg" alt="Images" height="500px" /></div>
                <h2>Airport Revenue Monitoring System (ARMS) </h2>
                <h5>Industry <span style="color: #01afee;">Airport</span></h5>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="services-left">

                    <p>
                        Techpool Airport Revenue Monitoring System is implemented in the second greenfield airport in
                        Kerala, built on Public Private Partnership model in an area of about 2,300 acres. Orientation
                        of the runway is 7/25, and the runway length of 3050 metres As per the Information Memorandum
                        prepared by SBI CAPS, the airport is expected to have an approximate annual traffic of 1.65
                        million international and 0.16 million domestic passengers . As most of the new generation
                        airports, they also operates on revenue sharing basis in most of its business operations. Like
                        other upcoming airports, Revenue monitoring was a real challenge for them also . There were
                        around 80+ different streams of revenue for them- but it was not practical to monitor those on
                        manual mode.
                    </p>
                    <b style="color: #01afee;">About the project</b>

                    <p>Techpool Airport Revenue Monitoring System (ARMS) helps them to monitor its different streams of
                        revenue like , Aero – Airlines charges, Passenger charges, User fee / Non Aero - Utility Charges
                        , Shops and other business center monitoring, parking charges , Taxi service etc . ARMS helps
                        them to streamline its business center revenue models and helps to monitor and plan the revenue
                        related aspects properly.</p>
                    <b style="color: #01afee;">Key Features / Benefits</b>
                    <ul>
                        <li>
                            Defined the business centers
                        </li>
                        <li>
                            Streamline the revenue sharing models
                        </li>
                        <li>
                            80 + streams of revenues and its periodic escalations are captured in system
                        </li>
                        <li>Linked the system to third party applications like Airlines Shedule data / passenger
                            information system etc </li>
                        <li>Linked the business streams revenue to KIAL's accounting system</li>
                        <li>Alerts & notification and reports to Management team and other stake holders</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:919633204833">+91 9633204833</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:info@techpool.co.in">info@techpool.co.in</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>
<!--
                    <div class="services-widget-two">
                        <h2>Brochures</h2>
                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a>PDF Download</a></span>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                    <div class="widget-category">
                        <h3>Other Products</h3>

                        <ul>
                            <li><a routerLink="/fintech">Fintech Application</a></li>
                            <li><a routerLink="/education">Education</a></li>
                            <li><a routerLink="/erp">Enterprise Resource Planning</a></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
