<footer class="footer-area footer-bg pt-25">
    <div class="container">
        <div class="footer-top">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="footer-img">
                        <img src="assets/img/logo/logo.png" width="200" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="footer-social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-midal pt-25">
            <div class="row">
                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Company</h3>
                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About the Company</a></li>
                            <li><a routerLink="/services">Services</a></li>
                            <li><a routerLink="/case-study">Case Study</a></li>
                            <li><a routerLink="/blog">Blog</a></li>
                            <!-- <li><a routerLink="/terms-conditions">Terms & Conditions</a></li> -->
                            <!-- <li><a routerLink="/privacy-policy">Privacy Policy</a></li> -->
                            <li><a routerLink="/contact">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget">
                        <h3>Services</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/software-services">Software Services</a></li>
                            <li><a routerLink="/it-services">IT Consultancy Services</a></li>
                            <li><a routerLink="/system-services">System Integration</a></li>
                            <li><a routerLink="/gis-services">GIS Services</a></li>
                            <li><a routerLink="/utility-services">Water & Utility Services</a></li>
                            <li><a routerLink="/cloud-services">Cloud Services</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget">
                        <h3>Contact Info</h3>
                    <p> Office: <a href="tel:+91 471 2446383">+91 471 2446383</a><br>
                     Sales Enquiry: <a href="tel:+91 9567761050">+91 9567761050</a><br>
                     Service Enquiry: <a href="tel:+91 9746124960">+91 9746124960</a><br>
                     HR Enquiry: <a href="tel:+91 9633204833">+91 9633204833</a></p>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                            <h3>Office Address</h3>
                            <p>2nd Floor, M G Corporate Building<br>Ulloor, Medical College P O<br>Trivandrum - 695011<br> Kerala, India<br>
                            <a href="mailto:info@techpool.co.in">info@techpool.co.in</a></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="copy-right-text text-center">
                <p>Copyright @2021. All Rights Reserved By <b>Techpool Consultancy Services</b> </p>
            </div>
        </div>
    </div>
</footer>
