<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area pt-100">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="services-left">
                <div class="services-content">
                    <img src="assets/img/service/services.png" alt="Images" />
                    <h2 style="text-align: center">
                        SYSTEM INTEGRATION SERVICES
                    </h2>
                    <p>
                        Nowadays the global marketplace is becoming fiercely
                        competent and challenging for each and every business.
                        Organisations have to make full use of technology to
                        stay ahead of the game. We along with our partners, help
                        our clients to achieve this goal with our rich IT
                        experience and technical capabilities to choose the
                        right IT products stacks for their environment.. We work
                        with a wide range of OEMs and provide the time, talents
                        and technology needed to convert a plan to an IT
                        solution for our clients
                    </p>
                    <p>
                        We partner with of the biggest names in the industry for
                        providing best solution to our clients and together we
                        ensure the technologies providing seamless integration
                        to your business.
                    </p>
                    <p>
                        Businesses in every industry are moving to the cloud,
                        looking to preserve cash, optimize information
                        technology (IT) costs, and support and secure users, who
                        are often working remotely. As they consider the best
                        ways to ensure agility and resiliency, they are
                        increasingly exploring multicloud and hybrid cloud
                        capabilities, including edge competencies. According to
                        IDC, by 2022, over 90% of enterprises worldwide will be
                        relying on a mix of on-premises/dedicated private
                        clouds, multiple public clouds, and legacy platforms to
                        meet their infrastructure needs. With <b>Hitachi</b>, we
                        enable our clients to design and optimize their IT
                        Computing Infrastructure .
                    </p>
                    <p>
                        <b>Numeric</b> is a market leader in the
                        Un-interruptible Power supplies and associated power
                        conditioning products in India with Global installations
                        spanning 12 countries. It is now a part of the Legrand
                        Group, a global specialist in electrical and digital
                        building infrastructures, occupying 4th position in the
                        Global UPS Market. Numeric is the undisputed UPS
                        Manufacturer and a reliable power solution partner for
                        the entire spectrum of user segments, Numeric provides
                        impeccable power quality and power continuity conforming
                        to International standards such as CE/ UL and RoHS
                        compliance. Numeric is ISO 9001: 2008, ISO 14001:2004,
                        BS OHSAS 18001 :2007 and SA 8000:2008 certified.
                        Techpool is the authorised sales and solution partner
                        for Numeric range of products in Kerala.
                    </p>
                    <p>
                        We are the critical difference partner for <b> Emerson
                            Networks, Fortinet, Cisco, Juniper Networks</b> in Kerala.
                    </p>
                    <p>
                        <b>Emerson Network Power (India) Private Ltd.</b>,(formerly Tata Liebert Limited) is a wholly
                        owned
                        subsidiary of Emerson Electric USA, $25 billion Fortune
                        500 Company.Emerson manufactures & markets
                        Uninterruptible Power Supply (UPS) systems(600VA to
                        8000KVA); Climate and Environmental systems (PAC); DC
                        power systems; Automatic Transfer Switches (ATS), power
                        distribution units (PDUs),Harmonic Filters and Surge
                        protection devices. Emerson SMART ROW&SMART CABINET – a
                        ready to deploy Data centre (RACK Integrated with
                        UPS/PAC/Access Control and Fire Alarm) – a perfect for
                        those who have to set up a server with maximum foot
                        print.Simple, fast, economical and allows maximum
                        utilization of your infrastructure.
                    </p>
                    <p>
                        <b>Fortinet</b> is a global leader and innovator in
                        Network Security. Fortinet is a provider of network
                        security appliances and security subscription services
                        for carriers, data centers, enterprises, distributed
                        offices and MSSPs. Techpool provides network security
                        services with Fortinet relation in Kerala.
                    </p>
                    <p>
                        <b>Cisco</b> is the worldwide technology leader that has
                        been making the Internet work since 1984. Cisco
                        engineers have been leaders in the development of
                        Internet Protocol (IP)-based networking technologies
                        since the company's inception. This tradition of
                        innovation continues with industry-leading products in
                        the core areas of routing and switching, as well as
                        advanced technologies in areas such as Unified
                        Communications, Network Security, Video, Virtualization
                        and Cloud Computing. Cisco has seven sales offices in
                        the region - New Delhi, Mumbai, Bangalore, Chennai,
                        Pune, Kolkata and Hyderabad. We Techpool Consultancy
                        Services is the authorised sales partner for CISCO in
                        Indi
                    </p>
                    <p>
                        Every day, <b>Juniper Networks</b> is helping customers
                        build the most advanced networks on the planet—networks
                        that do more than just connect services to people. They
                        build networks that fuel co-innovation, and actively
                        contribute to the ideas they power. Juniper’s goal is to
                        work as true partners with customers, bringing them the
                        capable and flexible solutions that not only modernize
                        their network, but enable it to deliver real business
                        value. We are the authorised sales partner for Juniper
                        in India
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
