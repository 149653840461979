<app-navbar-style-one></app-navbar-style-one>

<div class="case-study-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Case Study</span>
            <h2 class="h2-color">We’re Getting Ready <b>Stay Tuned!</b></h2>
        </div>
        <!-- <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies1.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-arms">Detection Project</a></h3>
                        <span>System Project</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies2.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Customer Segmentation</a></h3>
                        <span>Machine Learning</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies3.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Data Analysis</a></h3>
                        <span>Data Analysis</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies4.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Web Development</a></h3>
                        <span>Programming</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies5.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Artificial Intelligence</a></h3>
                        <span>Machine Learning</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies6.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Data Scientist</a></h3>
                        <span>Data Science</span>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
