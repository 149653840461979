<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="services-left">
                <div class="services-content">
                    <img src="assets/img/service/services.png" alt="Images">
                    <h2 style="text-align: center;">WATER & UTILITY SERVICES</h2>
                    <p>Techpool provides expert assistance to water and waste water utility management by providing
                        solutions and also resources who are having experience in respective departments (mostly retired
                        from Govt. Services) for addressing their complex issues related to engineering, accounting,
                        economic, management, operational, policy &regulatory, etc. Our consultant’s capabilities and
                        experience encompass the broad areas of water utility management and regulatory services.</p>
                    <p>Techpool with its heritage expertise in Information Technology (IT), offers our clients on
                        support on an IT enabled utility management and litigations. We use our expertise to identify
                        and develop strategies to ease the transfer and use of data, GIS surveys, Enterprise resource
                        planning (ERP) solutions, Automated Smart Metering & Billing solutions, Leakage detection and
                        NRW reduction solutions, digitization of processes etc, in water and utility areas.</p>
                    <p>Techpool derives its strength from a collection of technocrats and retired heads with specialized
                        training in the discipline of water and utility systems. Our highly experienced team of
                        professional consultants brings together combined expertise in advanced analytics and practical
                        business sense with extensive technology and engineering capabilities. We deliver solutions that
                        work best for needs, organization, assets and customers. The challenges of the water industry
                        are met head on each day by our industry-leading water consulting professionals. They work to
                        collaborate with clients and help them design new ways to create more sustainable and resilient
                        solutions to manage water resources.</p>
                    <p>Our vision is to truly guide our customers and to help them to achieve the best practices and
                        technologies in water and utility areas. Our Business Technology & Architecture offering is
                        designed to enable our clients to adopt, deploy, operate and exploit investments in
                        next-generation intelligent assets that can transform their operations and revenue generation
                        capabilities. We have taken the first steps in this industry to develop capabilities and process
                        and technology models for our energy clients and can add value by helping them to identify the
                        right solutions, through the design, implementation and transformation of new technologies and
                        processes.</p>
                    <h5>WATER AND POWER SCADA SOLUTIONS</h5>
                    <p>
                        With Latest automation solutions (RTU/MODBUS/IOT/etc.) we are capable to redesign any Water
                        Treatment Plants (WTP), Transmission/Distribution networks, etc, to have a comprehensive view of
                        existing plant by integrating data from all plant areas and systems, including remote
                        Supervisory Control and Data Acquisition (SCADA) systems. Open architecture in the control
                        system seamlessly consolidates and rationalizes plant data, and enhances operator response to
                        changing conditions. Thereby, in turn, improves both plant safety and uptime.
                    </p>
                    <p>
                        Our services are aim to provide safe drinking water and effective wastewater management to
                        communities we live. We serve public and private clients of every size with a strong focus on
                        life cycle economy, efficiency and reliability.
                    </p>
                </div>

                <!--
                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For Content Marketer</h2>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> The Field of Data Science</li>
                                        <li><i class='bx bx-check'></i> SEO is Uniquely Built Around  </li>
                                        <li><i class='bx bx-check'></i> Google’s search algorithm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->

                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore tus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis onsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
