import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-utility-services',
  templateUrl: './utility-services.component.html',
  styleUrls: ['./utility-services.component.scss']
})
export class UtilityServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
