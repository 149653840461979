<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area">
    <div class="container">
        <div class="row">

            <div class="col-lg-12 col-md-12" style="margin-bottom: 20px;">
                <div style="text-align: center;"><img src="assets/img/service/erp.png" alt="Images" height="500px" /></div>
                <h2>Accounts - Budgeting - Inventory Management System</h2>
                <h5>Industry <span style="color: #01afee;">Government / Corporate Institutions </span></h5>

            </div>
            <div class="col-lg-8 col-md-12">
                <div class="services-left">

                    <p>
                        An University level application with around 40 plus Education Institutes affiliated to it. Was
                        handling around 200Cr budget every year through manual Budget allocation processes. Each
                        institute was following different procurement models , separate inventory management system and
                        accounting practices. Centralized Budgeting, Procurement, Inventory management and accounting
                        was their main paint point during those days
                    </p>
                    <b style="color: #01afee;">About the project</b>

                    <p>We proposed a Centralized Budgeting system with clear fund allocation / procurement /Inventory
                        management system with centralized accounting for the university.. This helps them to have
                        centralized system with a better control on budgeting / utilization and Inventory. </p>
                    <b style="color: #01afee;">Key Features / Benefits</b>
                    <ul>
                        <li>
                            Centralized Budgeting </li>
                        <li>
                            Controlled Budget utilization
                        </li>
                        <li>
                            Monitored Procurement System with defined policies
                        </li>
                        <li>Perfect Inventory management system with clear depreciation calculation</li>
                        <li>Centralized Accounting System</li>
                        <li>Alerts & notification and reports</li>
                        <li>Management Information System (MIS)</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="services-widget-right">
                    <div class="contact-widget">
                        <h2>Contact Info</h2>

                        <ul>
                            <li>
                                <i class="flaticon-telephone"></i>
                                <div class="content">
                                    <h3>Phone: </h3>
                                    <span><a href="tel:919633204833">+91 9633204833</a></span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-email-1"></i>
                                <div class="content">
                                    <h3>Email:</h3>
                                    <span><a href="mailto:info@techpool.co.in">info@techpool.co.in</a></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <!-- <div class="services-widget-two">
                        <h2>Brochures</h2>

                        <ul>
                            <li>
                                <i class="flaticon-pdf-file"></i>
                                <div class="content">
                                    <h3>01:</h3>
                                    <span><a>PDF Download</a></span>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                    <div class="widget-category">
                        <h3>Other Products</h3>

                        <ul>
                            <li><a routerLink="/fintech">Fintech Application</a></li>
                            <li><a routerLink="/education">Education</a></li>
                            <li><a routerLink="/aero">Aero Management</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer-style-one></app-footer-style-one>
