<footer class="footer-area footer-bg2 pt-100">
    <div class="container">
        <div class="footer-midal pb-70">
            <div class="row">
                <div class="col-lg-4 col-sm-7">
                    <div class="footer-widget">
                        <div class="footer-img">
                            <img src="assets/img/logo/logo1.png" alt="Images">
                        </div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                        <div class="footer-social-icon">
                            <ul class="social-link">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li> 
                                <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li> 
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-5">
                    <div class="footer-widget">
                        <h3>Resources</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Our Scientists</a></li>
                            <li><a routerLink="/">Our Services</a></li>
                            <li><a routerLink="/">Testimonials</a></li>
                            <li><a routerLink="/">SaaS Solutions</a></li>
                            <li><a routerLink="/">eCommerce</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-7">
                    <div class="footer-widget pl-5">
                        <h3>Company</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/">Home</a></li>
                            <li><a routerLink="/about">About</a></li>
                            <li><a routerLink="/case-study">Case Studies</a></li>
                            <li><a routerLink="/blog">Our Blog</a></li>
                            <li><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Address</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+4498886660000">+449 888 666 0000</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:hello@zinka.com">hello@zinka.com</a></li>
                            <li><i class='flaticon-place'></i>855 road, brooklyn street new york 600</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="row">
                <div class="col-lg-8">
                    <div class="copy-right-text text-start">
                        <p>Copyright @2021 Ribo. All Rights Reserved by <a href="https://hibootstrap.com/" target="_blank">HiBootstrap</a> </p>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="copy-right-list">
                        <ul>
                            <li><a routerLink="/privacy-policy"> Privacy Policy</a></li>
                            <li><a routerLink="/terms-conditions"> Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>