<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Services Details</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div> -->

<div class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <div class="services-left">
                <div class="services-content">
                    <img src="assets/img/service/services.png" alt="Images">
                    <h2 style="text-align: center;">CLOUD SERVICES</h2>
                    <p>Techpool is offering different levels of cloud services that suites the customer needs. We are
                        the authorized channel partner for leading cloud service providers like CtrlS, AWS, ESDS, Nxtra
                        Data and Nxtgen</p>
                    <p>Our Coud Services offerings:</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                Cloud Infra – Private / Public / Hybrid
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                Virtual Desktop Infrastructure (VDI)
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>
                                DC and DR Solutions
                            </h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>Process Automation Solution</h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="services-content-card">
                            <h3>SAP ( ECC / S4 HANA )</h3>
                        </div>
                    </div>
                </div>
                <div>
                    <h5>Benefits of cloud computing</h5>
                    <p>Cloud computing offers your business many benefits. It allows you to set up what is essentially a
                        virtual office to give you the flexibility of connecting to your business anywhere, any time.
                        With the growing number of web-enabled devices used in today's business environment (e.g.
                        smartphones, tablets), access to your data is even easier.</p>
                    <p>There are many benefits to moving your business to the cloud:</p>
                    <b style="color: #01afee;">Reduced IT Costs</b>
                    <p>Moving to cloud computing may reduce the cost of managing and maintaining your IT systems. Rather
                        than purchasing expensive systems and equipment for your business, you can reduce your costs by
                        using the resources of your cloud computing service provider. You may be able to reduce your
                        operating costs because:</p>
                    <ul>
                        <li>The cost of system upgrades, new hardware and software may be included in your contract</li>
                        <li>You no longer need to pay wages for expert staff</li>
                        <li>Your energy consumption costs may be reduced</li>
                        <li>Avoid time delays</li>
                    </ul>
                    <b style="color: #01afee;">Scalability</b>
                    <p>Your business can scale up or scale down your operation and storage needs quickly to suit your
                        situation, allowing flexibility as your needs change. Rather than purchasing and installing
                        expensive upgrades yourself, your cloud computer service provider can handle this for you. By
                        using the cloud frees up your time so you can get on with running your business. You can focus
                        on your core competency and we take care of your IT Infrastructure Management pain.</p>
                    <b style="color: #01afee;">Business Continuity</b>
                    <p>Protecting your data and systems is an important part of Business Continuity Planning (BCP).
                        Whether you experience a natural disaster, power failure or other crisis, having your data
                        stored in the cloud ensures it is backed up and protected in a secure and safe location. Being
                        able to access your data again quickly allows you to conduct business as usual, minimizing any
                        downtime and loss of productivity.</p>
                    <b style="color: #01afee;">Collaboration Efficiency</b>
                    <p>Collaboration in a cloud environment gives your business the ability to communicate and share
                        more easily outside of the traditional methods. If you are working on a project across different
                        locations, you could use cloud computing to give employees, contractors and third parties access
                        to the same files. You could also choose a cloud computing model that makes it easy for you to
                        share your records with your advisers (e.g. a quick and secure way to share accounting records
                        with your accountant or financial adviser).</p>
                    <b style="color: #01afee;">Flexibility Of Work Practices</b>
                    <p>Cloud computing allows employees to be more flexible in their work practices. For example, you
                        have the ability to access data from home, on holiday, or via the commute to and from work
                        (providing you have an internet connection). If you need access to your data while you are
                        off-site, you can connect to your virtual office, quickly and easily.</p>
                    <b style="color: #01afee;">Access To Automatic Updates</b>
                    <p>Access to automatic updates for your IT requirements may be included in your service fee.
                        Depending on your cloud computing service provider, your system will regularly be updated with
                        the latest technology. This could include up-to-date versions of software, as well as upgrades
                        to servers and computer processing power.</p>
                    <b style="color: #01afee;">Cloud Offers You Optimum Information Security </b>
                    <p>Our solutions understand the future state of your business and risk-based security program,
                        establishing cloud security at every layer of the stack to enable your business goals. Integrate
                        native cloud security controls, implement secure-by-design methodology and establish security
                        orchestration and automation to define and enforce your enterprise cloud security program.</p>
                </div>

                <!--
                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>The Entire Solution For Content Marketer</h2>
                                    <p>Lorem ipsum dolor sit ametaut odiut perspiciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> The Field of Data Science</li>
                                        <li><i class='bx bx-check'></i> SEO is Uniquely Built Around  </li>
                                        <li><i class='bx bx-check'></i> Google’s search algorithm</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> -->

                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore tus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis onsectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p> -->
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
