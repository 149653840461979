<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="title">
                        <i class="flaticon-idea"></i>
                        <span>Connecting Technologies</span>
                        <!-- <span>Leaders in IT Consultancy</span> -->

                    </div>
                    <h1><b>We Keep it<br></b> Simple. Real. Better.</h1>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor avida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p> -->
                    <div class="banner-btn">
                        <a routerLink="/contact" class="get-btn">Get started<i class='bx bx-right-arrow-alt'></i></a>
                        <!-- <a href="tel:+123-098-456-098" class="call-btn"><i class='bx bx-plus'></i>+123-098-456-098</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape-right"><img src="assets/img/home1/home-one-shape.png" alt="Images"></div>
    <div class="banner-img"><img src="assets/img/home1/home1.png" alt="Images"></div>
    <div class="banner-shape">
        <div class="shape1"><img src="assets/img/home1/home-one-shape3.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/home1/home-one-shape1.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/home1/home-one-shape2.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/home1/home-one-shape4.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/home1/home-one-shape5.png" alt="Images"></div>
    </div>
</div>


<div class="counter-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-confetti"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>GLORIOUS YEARS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-project"></i>
                    <div class="content">
                        <h3>25 +</h3>
                        <p>PROJECTS DONE</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-customers"></i>
                    <div class="content">
                        <h3>150 +</h3>
                        <p>ACTIVE CLIENTS</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-financial-advisor"></i>
                    <div class="content">
                        <h3>10 +</h3>
                        <p>TEAM ADVISORS</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area">
    <div class="container">
        <div class="section-title">
            <span class="sp-before sp-after">Services</span>
            <h2 class="h2-color">We’re Flexible to <b>Provide You Best</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="services-card-into">
                    <div class="services-card" routerLink="/software-services">
                        <img src="assets/img/service/data.png" alt="Images">
                        <h3>SOFTWARE SERVICES</h3>
                        <p><b>WE</b> offers state of art software services including web application development, web platform development, mobile application development, hospital information systems and lot more. </p>
                        <div class="services-card-shape">
                            <img src="assets/img/service/service-shape.png" alt="Images">
                        </div>
                    </div>
                    <div class="services-dots"><img src="assets/img/service/dots.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card" routerLink="/it-services">
                    <img src="assets/img/service/support-services.png" alt="Images">
                    <h3>IT CONSULTANCY SERVICES</h3>
                    <p><b>WE</b> offers services in IT consultancy, project consultancy, DPR preparation, project monitoring and management, network security, system design and hardware, data centre, power auditing and electrical consultancy services.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
                <div class="services-card" routerLink="/system-services">
                   <img src="assets/img/service/system.png" alt="Images">
                    <h3>SYSTEM INTEGRATION SERVICES</h3>
                    <p> <b>WE</b> offers flawless, scalable and user friendly customised integrated system with a reliable back end support.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card" routerLink="/gis-services">
                   <img src="assets/img/service/gis.png" alt="Images">
                    <h3>GIS SERVICES</h3>
                    <p><b>WE</b> offers high quality professional education, Foster communications among IT/GIS
                        management professionals in provincial/state and local government, and Encourage a multi-disciplinary approach to the design and use of urban and regional information systems</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
                <div class="services-card" routerLink="/utility-services">
                   <img src="assets/img/service/utility-rate.png" alt="Images">
                    <h3>WATER & UTILITY CONSULTANCY SERVICES</h3>
                    <p><b>WE</b> offers expert assistance to water and waste water utility management by providing solutions and also resources who are having experience in respective departments for addressing their complex issues related to engineering, accounting, economic, management, operational, policy &regulatory, etc. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
                <div class="services-card" routerLink="/cloud-services">
                    <img src="assets/img/service/cloud.png" alt="Images">
                    <h3>CLOUD SERVICES</h3>
                    <p><b>WE</b> offers services on cloud include,Cloud hosting Service, Hosted data back service, Cloud Hosted application services and lot more. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="services-shape">
        <div class="services-bg"><img src="assets/img/service/service-bg.png" alt="Images"></div>
        <div class="shape1"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>



<div class="about-area pt-50">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="assets/img/about/about-img1.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="section-title">
                            <span class="sp-before sp-after">About us</span>
                            <h2 class="h2-color">Grow Your Business <b>With Techpool</b></h2>
                        </div>
                        <p>We <b>Techpool</b> has completed our 10th successful year- with year on year positive net worth growth and striving to build a strong customer ecosystem which finds genuine value in its mutual engagement.
                        </p>
                        <p><b>Techpool</b> is managed by a group of directors, who have wide experience in IT industry and proven professional track records with globally leading MNCs. TechPool Consultancy Services offers Software
                            development services and IT Business Consulting Services in those domains where convergence of innovative ideas and technology takes place. With our rich heritage expertise in the fields of Information Technology,
                              we provide guidance for organisations for their IT and Business roadmap as well. We committed to excellence in delivering superior quality solutions and extended service supports. </p>
                        <div class="about-btn">
                            <a routerLink="/about" class="default-btn">Learn More<i class='bx bx-plus'></i></a>
                            <!-- <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-btn popup-btn"><i class="flaticon-play-button"></i><span> Watch 1min <br>Play demo</span></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <div class="shape-1"><img src="assets/img/about/about-shape1.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape3.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
    </div>
</div>

<div class="courses-area" id="products">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Our Products</span>
            <h2 class="h2-color">Products</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud </p> -->
        </div>

        <!-- <div class="row pt-25">
            <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/arms.png" alt="Images">
                    </div>
                    <h3>Airport Revenue Monitoring System</h3>
                    <p><b>Techpool Airport Revenue Monitoring System</b> will help Airports to identify, define and monitor all the conventional revenue streams in an airport.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/forex.png" alt="Images">
                    </div>
                    <h3>Forex Management Solution</h3>
                    <p><b>Techpool Forex Management Solution</b> covers the all essential features required to optimize the risk and maximize the profitability for NBFC's.</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/budget.png" alt="Images">
                    </div>
                    <h3>Budgeting Procurement & Inventory Management System</h3>
                    <p><b>Techpool Budgeting Procurement & Inventory Management System</b> provides a centralized budgeting system with clear fund allocation / procurement / inventory management system with centralized accounting.</p>
                </div>
            </div>

             <div class="col-lg-6">
                <div class="courses-card" routerLink="/">
                    <div class="images">
                        <img src="assets/img/courses/data.png" alt="Images">
                    </div>
                    <h3>Plug & Play DC for Immediate Deployement</h3>
                    <p>Lorem ipsum dolor sit ametaut odiut psciatis unde omnis iste quuntur alquam quaerat rsit amet</p>
                </div>
            </div>
        </div> -->
        <div class="row pt-25">
            <div class="col-lg-6">
                <div class="courses-card" routerLink="/fintech">
                    <div class="images">
                        <img src="assets/img/courses/forex.png" alt="Images">
                    </div>
                    <h3>Fintech Application</h3>
                    <p>Financial application like KYC-cKYC, Forex etc which are emerging into the industry by complying the regulations and providing effective solutions in the operational activities</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/education">
                    <div class="images">
                        <img src="assets/img/courses/education.png" alt="Images">
                    </div>
                    <h3>Education</h3>
                    <p>An an enterprise class EDU-ERP system specially designed for 21st century educational Institutions based on their priorities. It takes care of the requirements of all the stakeholders- management, teachers, operational staffs, students and parents</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="courses-card" routerLink="/aero">
                    <div class="images">
                        <img src="assets/img/courses/arms.png" alt="Images">
                    </div>
                    <h3>Aero Management</h3>
                    <p>Techpool Airport Revenue Monitoring System will help Airports to identify, define and monitor all the conventional revenue streams in an airport</p>
                </div>
            </div>

             <div class="col-lg-6">
                <div class="courses-card" routerLink="/erp">
                    <div class="images">
                        <img src="assets/img/courses/budget.png" alt="Images">
                    </div>
                    <h3>Enterprise Resource Planning</h3>
                    <p>Techpool Budgeting Procurement & Inventory Management System provides a centralized budgeting system with clear fund allocation / procurement / inventory management system with centralized accounting</p>
                </div>
            </div>
        </div>

    </div>
</div>
<!--
<div class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Working Process</span>
            <h2 class="h2-color">Simple & Clean <b>Work Process</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <h2>01</h2>
                    <h3>Onsite Optimization</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card active">
                    <h2>02</h2>
                    <h3>Keyword Research</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="work-card">
                    <h2>03</h2>
                    <h3>Link Building</h3>
                    <p>Lorem ipsum dolor sit amet, aut odiut perspiciatis unde omnis iste natus odit afugitsed quia consequuntur </p>
                </div>
            </div>
        </div>
    </div>

     <div class="work-shape"><img src="assets/img/shape/work-shape.png" alt="Images"></div>
</div> -->

<div class="brand-area">
    <div class="container-fluid">
        <div class="brand-max">
            <div class="brand-title">
                <h3>Over <span style="color:#01afee">150+</span> clients all over the world</h3>
            </div>
            <div class="brand-slider owl-carousel owl-theme">
                <div class="brand-item">
                  <img src="assets/img/clients/kel_logo.png" alt="Images">
                </div>
                <div class="brand-item"><img src="assets/img/clients/asap_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                   <img src="assets/img/clients/cdit_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/kial_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/dbfs_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/ncs_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/vikas_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/it_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/psc_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/ksdma_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/qrs_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/nav_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/veega_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/wonderla_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/nesk_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/geojit_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/cms_logo.png" alt="Images">
                </div>
                <div class="brand-item">
                    <img src="assets/img/clients/kp_logo.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="blog-area blog-bg pt-50 pb-50">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-title">
                    <div class="section-title">
                        <span class="sp-before sp-after">Our Blogs</span>
                        <h2 class="h2-color">Latest Valuable <b>Insights</b></h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="blog-btn">
                    <a routerLink="/blog" class="default-btn">See all news<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 09 April 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">The Home of Technology is in Front of You</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i>10 July 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">SEO Best Practices Mobile Friendliness</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.png" alt="Images"></a>
                    <div class="content">
                        <ul>
                            <li><i class='bx bx-time-five'></i> 19 June 2020</li>
                            <li><i class='bx bx-purchase-tag-alt'></i> <a routerLink="/blog">SEO & Marketing</a></li>
                        </ul>
                        <h3><a routerLink="/blog-details">15 SEO Practices Website Architecture</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape">
        <div class="shape1"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape5.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape6.png" alt="Images"></div>
    </div>
</div> -->

<app-footer-style-one></app-footer-style-one>
